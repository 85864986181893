import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import React from 'react'
import MetaImage from '../assets/images/product/investment-balance/investment-balance-meta.png'
import Layout from '../components/Layout'
import ColumnsBlock from '../components/blocks/ColumnsBlock'
import FooterBlock from '../components/blocks/FooterBlock'
import HeroBlock from '../components/blocks/HeroBlock'
import IllustrationBlock from '../components/blocks/IllustrationBlock'
import LeadBlock from '../components/blocks/LeadBlock'
import QuoteBlock from '../components/blocks/QuoteBlock'
import ToolsBlock from '../components/blocks/ToolsBlock'
import Box from '../components/primitives/Box'
import Blurb from '../components/system/Blurb'
import GlobalStyleReset from '../components/system/GlobalStyleReset'
import { responsiveScale } from '../styles/helpers'
export const query = graphql`
  {
    backgroundImage: file(
      relativePath: { eq: "images/product/investment-balance/hero-bg.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    lead: file(
      relativePath: { eq: "images/product/investment-balance/lead.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1340)
      }
    }
    money: file(
      relativePath: {
        eq: "images/product/investment-balance/cost-capitalization.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 670)
      }
    }
    individuals: file(
      relativePath: { eq: "images/product/investment-balance/dev-overview.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 670)
      }
    }
    teams: file(
      relativePath: { eq: "images/product/investment-balance/focus.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 670)
      }
    }
    investmentBalance1: file(
      relativePath: { eq: "images/product/investment-balance/drill-down.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 438)
      }
    }
    investmentBalance2: file(
      relativePath: { eq: "images/product/investment-balance/ktlo.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 438)
      }
    }
    investmentBalance3: file(
      relativePath: {
        eq: "images/product/investment-balance/investment-filters.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 438)
      }
    }
  }
`

const InsightsPage = () => {
  const data = useStaticQuery(query)

  return (
    <Layout
      title="CTO dashboard"
      variant="dark"
      isNew
      description="The complete CTO dashboard for tracking KPIs, OKRs, metrics, and to understand where engineering time is going so you can strike the right balance between different types of work, from maintenance to roadmap work and more."
      metaImage={MetaImage}
    >
      <GlobalStyleReset>
        <HeroBlock
          title={
            <>
              The complete CTO&nbsp;dashboard for KPIs, OKRs, and&nbsp;metrics
            </>
          }
          content="Use the engineering dashboards in Swarmia to get a clear picture of where teams are spending their time and strike the right balance between different types of work."
          backgroundImage={getImage(data.backgroundImage)!}
        />
        <Box
          css={`
            margin-top: -48px;
          `}
        >
          <IllustrationBlock
            paddingTop={{ xs: 32, sm: 0 }}
            paddingBottom={responsiveScale(32)}
            image={getImage(data.lead)!}
            noPlaceHolder
            rounded
            shadow
          />
        </Box>
        <LeadBlock
          heading="Build a healthier, more balanced engineering organization"
          content="Leading an engineering organization is a balancing act. Your goal as the CTO is to intentionally manage the investment between building new things, reducing technical debt, and improving productivity."
          link={{
            title: 'Read our guide to balancing engineering investment',
            href: '/blog/balancing-engineering-investments',
          }}
        />

        <ColumnsBlock
          paddingTop={responsiveScale(48)}
          paddingBottom={responsiveScale(64)}
          columns={[
            <Blurb
              key="1"
              title="Understand the big picture"
              text="Look at investment distribution by organization or team to spot larger trends."
              isLarge
              image={getImage(data.teams)!}
            />,
            <Blurb
              key="2"
              title="Translate initiatives into dollars"
              text="Clearly see how much money your business is spending on different projects."
              isLarge
              image={getImage(data.money)!}
            />,
            <Blurb
              key="3"
              title="Focus on key objectives"
              text="Understand where engineering time goes, and improve planning and prioritization."
              image={getImage(data.investmentBalance1)}
            />,
          ]}
        />

        <LeadBlock
          heading={<>Improve balance on every level of&nbsp;the organization</>}
          content="Identify teams that need support. Help individuals understand where their time goes, without stalking or stack ranking them."
        />

        <ColumnsBlock
          paddingTop={responsiveScale(64)}
          paddingBottom={responsiveScale(64)}
          columns={[
            <Blurb
              key="1"
              title="Support and coach individuals"
              text="Have high-quality 1:1s and career discussions based on real insights."
              image={getImage(data.individuals)!}
            />,
            <Blurb
              key="2"
              title="Get maintenance under control"
              text="Identify time-consuming patterns in technical debt, firefighting, and chores."
              image={getImage(data.investmentBalance2)}
            />,
            <Blurb
              key="3"
              title="Auto-categorize work in minutes"
              text="Set up investment categories in a few clicks. Swarmia will take care of the rest."
              image={getImage(data.investmentBalance3)}
            />,
          ]}
        />

        <ToolsBlock />
        <QuoteBlock person="alex" />
        <FooterBlock productPages={['insights', 'workingAgreements']} />
      </GlobalStyleReset>
    </Layout>
  )
}

export default InsightsPage
